import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormGroup } from 'reactstrap';
import FormInput from '../components/portal/elements/FormInput';
import { useApi } from '../components/portal/elements/ApiState';
import { usePageState } from '../components/PageState';

const FormContext = React.createContext(null);

function getChangeValue(setState) {
	return name => {
		return e => {
			setState(state => {
				let value;
				if(typeof e === 'function') {
					value = e(state?.values?.[name]);
				} else {
					value = e.target.value;
				}
				let newState = state;
				if(!state.values) {
					newState = { ...state, values: { [name]: value } };
				} else if(state.values[name] !== value || !(name in state.values)) {
					newState = { ...state, values: { ...state.values, [name]: value } };
				}
				if(newState !== state) {
					newState.context = { ...state.context, values: newState.values };
				}
				return newState;
			});
		};
	};
}

function useValues() {
	const [state, setState] = useState({
		values: null,
		context: {
			values: null,
			changeValue: null
		}
	});
	[state.context.changeValue] = useState(() => getChangeValue(setState));
	const updateValues = values => {
		setState(prev => {
			if(typeof values === 'function') {
				values = values(prev.values);
			}
			if(prev.values === values) {
				return prev;
			}
			return { ...prev, values, context: { ...prev.context, values } };
		});
	};
	return { state, setState, updateValues };
}

function useAutosubmit(onChange, values, forceFormData) {
	const { api, submitForm } = useApi();
	const [prevValues, setPrevValues] = useState(values);
	useEffect(() => {
		if(api && onChange) {
			if(typeof onChange === 'object') {
				const { url, names } = onChange;
				const prev = prevValues || {};
				const vals = values || {};
				for(const key of names) {
					if(prev[key] !== vals[key]) {
						submitForm(api, url, values, forceFormData);
						break;
					}
				}
			} else {
				submitForm(api, onChange, values, forceFormData);
			}
		}
		setPrevValues(values);
	}, [values]);
}

function checkRequired(require, values) {
	if(Array.isArray(require)) {
		return values !== null && require.every(rule => {
			if(rule.notEmpty) {
				const value = values[rule.notEmpty];
				return value && (!Array.isArray(value) || value.length);
			} else if(rule.equals) {
				const v = values[rule.equals[0]];
				return rule.equals.every(value => values[value] === v);
			} else if(rule.notIn) {
				return rule.notIn.values.every(value => values[rule.notIn.name] !== value);
			}
			return false;
		});
	}
	return true;
}

export function FormProvider({ context, children }) {
	return <FormContext.Provider value={context} children={children} />;
}

export function useFormContext() {
	return useContext(FormContext);
}

export default Child => {
	return ({ inputs, onChange, ...props }) => {
		const { values: pageValues, actions } = usePageState();
		const setValues = props.id && pageValues.find(f => f.form === props.id)?.values;
		const { state, updateValues } = useValues();
		useEffect(() => {
			const values = {};
			inputs?.forEach(input => {
				FormInput.defaultValue(input, v => {
					values[input.name] = v;
				});
			});
			updateValues(prev => ({ ...prev, ...values }));
		}, [inputs]);
		useEffect(() => {
			if(setValues) {
				updateValues(prev => ({ ...prev, ...setValues }));
				actions.clearFormValue(props.id);
			}
		}, [setValues, actions.clearFormValue]);
		const { values, context } = state;
		useAutosubmit(onChange, values, props.forceFormData);
		const renderInputs = useMemo(() => {
			return () => {
				return inputs && values && inputs.map((input, i) => {
					const { key, ...pass } = input;
					pass[FormInput.valueKey(input)] = values[input.name];
					pass.onChange = context.changeValue(input.name);
					return <FormGroup key={key || i}>
						<FormInput {...pass} />
					</FormGroup>;
				});
			};
		}, [inputs, values]);
		const canSubmit = useMemo(() => require => checkRequired(require, values));
		if(inputs && !values) {
			return null;
		}
		return <FormProvider context={context}>
			<Child {...props} renderInputs={renderInputs} values={values} canSubmit={canSubmit} />
		</FormProvider>;
	};
};

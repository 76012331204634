import React, { useEffect, useMemo } from 'react';
import FormInput from './FormInput';
import { useFormContext } from '../../../hoc/form';

export default function Input(originalProps) {
	const context = useFormContext();
	const { name } = originalProps;
	useEffect(() => {
		if(name && context?.changeValue && (!context.values || !(name in context.values))) {
			FormInput.defaultValue(originalProps, value => {
				context.changeValue(name)({ target: { value } });
			});
		}
	}, [originalProps, context]);
	const onChange = useMemo(() => {
		if(name && context?.changeValue) {
			return context.changeValue(name);
		}
	}, [name, context?.changeValue]);
	const props = { ...originalProps };
	if(name && context?.changeValue) {
		props.onChange = onChange;
		if(!context.values || !(name in context.values)) {
			return null;
		}
		props[FormInput.valueKey(props)] = context.values[name];
	}
	return <FormInput {...props} />;
}

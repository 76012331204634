import React, { useState, useEffect } from 'react';
import { Navbar, NavbarToggler, Collapse, Nav, NavItem } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { useReload } from '../PageState';
import { useLogin, useLogout, useUser } from '../UserState';

export default function Header({ updateHeaders, id }) {
	const login = useLogin();
	const logout = useLogout();
	const { email, loggedIn } = useUser().user;
	const [open, setOpen] = useState(false);
	const location = useLocation();
	useReload(id, updateHeaders);
	useEffect(() => {
		setOpen(false);
	}, [location]);
	return <header>
		<Navbar expand="sm" fixed="top" className="bg-white">
			<Link to="/" className="navbar-brand">
				<img src="/logo.png" className="brand-icon" alt="PTMD" />
			</Link>
			<NavbarToggler onClick={() => setOpen(!open)} />
			<Collapse isOpen={open} navbar>
				<Nav className="ml-auto" navbar>
					<NavItem>
						{loggedIn ?
							<Link className="nav-link" to="/" onClick={logout}>
								{email}
								<img src="/logout.png" alt="Logout" className="brand-icon ml-1" />
							</Link>
							: <Link className="nav-link" to="/" onClick={() => login(true)}>Login</Link>}
					</NavItem>
				</Nav>
			</Collapse>
		</Navbar>
	</header>;
}

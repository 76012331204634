import React, { useState } from 'react';
import ErrorAlert from './ErrorAlert';
import { useLogin } from './UserState';

export default function LoginPage() {
	const [error, setError] = useState(null);
	const login = useLogin(setError);
	return <div className="container">
		<header className="mb-2 mt-2">
			<a href="https://ptmd.nl" className="d-block mx-auto col-md-3 col-lg-2">
				<img alt="PTMD" src="/logo.png" className="mw-100" />
			</a>
		</header>
		<main className="p-3 rounded-lg text-center">
			<h1 className="mb-4">PTMD Portal</h1>
			{error && <ErrorAlert error={error} />}
			<p>
				<button className="btn btn-success btn-lg" onClick={e => {
					e.preventDefault();
					login();
				}} autoFocus>Login</button>
			</p>
		</main>
	</div>;
}
